import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Anchor, Box, Image, Heading } from "../../components/elements";
import { Container, Row, Col } from "react-bootstrap";
import data from "../../data/supports/overview.json";

export default function HomePage() {
    return (
        <PageLayout>
            <Container fluid>
                <Heading as="h2" className="mc-overview-title">0X Factory</Heading>
                    <div className="text-center">
                        <h3>Download deceterlized exchange, smart contracts, tokens creator dapp, launchpad dapp, airdrop dapp and many tokens solidity code.</h3>
                    </div>
                    <br/>
                    <Row xs={1} sm={2} xl={3}>
                        {data?.home.map((item, index) => (
                            <Col key={index} >
                                <Box className="mc-overview-card">
                                    <Heading className="mc-overview-name">{item.title}</Heading>
                                    <Box className="mc-overview-media">
                                        <Image src={item.image} alt="demo" />
                                        <h3>{item.price}</h3>
                                        <p>
                                            {item.description}
                                        </p>
                                        <Box className="mc-overview-overlay">
                                            <Anchor href={item.path} rel="noopener noreferrer" className="mc-overview-link">Start Now</Anchor>
                                        </Box>
                                    </Box>
                                </Box>
                                <center><a href={item.demo} rel="noopener noreferrer" target="_blank"><h3 style={{color: 'orange'}}>Live Demo</h3></a></center>
                            </Col>
                        ))}
                    </Row>

                </Container>
        </PageLayout>
    )
}