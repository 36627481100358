import React from "react";
import { useEffect, useState } from 'react';
import PageLayout from "../../layouts/PageLayout";
import { DivideTitle } from "../../components";
import { Row, Col } from "react-bootstrap";
import { CardHeader } from "../../components/cards";
import { LabelField } from "../../components/fields";
import { Box, Button } from "../../components/elements";
import { ethers } from 'ethers';
import { useNavigate } from "react-router-dom";

export default function UsdtConverter() {

    const contractAddress = "0xa17530021C7fbCD973F9D671f16caeE4fC21687F";
    const abi = [{"inputs":[{"internalType":"address","name":"_cOwnerAddress","type":"address"}],"name":"createUsdtConverter","outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"payable","type":"function"},{"inputs":[],"stateMutability":"nonpayable","type":"constructor"},{"inputs":[{"internalType":"address payable","name":"beneficiary","type":"address"}],"name":"withdrawEther","outputs":[],"stateMutability":"nonpayable","type":"function"},{"inputs":[],"name":"getNewUsdtConverter","outputs":[{"internalType":"contract UsdtConverter","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"newUsdtConverter","outputs":[{"internalType":"contract UsdtConverter","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"theOwner","outputs":[{"internalType":"address payable","name":"","type":"address"}],"stateMutability":"view","type":"function"}];
    const fee = "10000000000000000";

    let myNewContract = "";
    let tempNewContract = "";
    const navigate = useNavigate();

    const [currentAccount, setCurrentAccount] = useState(null);
    const [ContractText, setContractText] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [createButtonText, setCreateButtonText] = useState("Create Contract")
    const [createButtonIcon, setCreateButtonIcon] = useState("add")
    const [createButtonColor, setCreateButtonColor] = useState("mc-btn green m-2")

    
    
      const handleContractChange = (event) => {
        setContractText(event.target.value);
      };

      const checkWalletIsConnected = () => {
        const { ethereum } = window;
        if(!ethereum){
          console.log("Metamask NOT Installed");
          return;
        }else{
          console.log("Metamask Installed");
        }
    
       }
    
    
      const connectWalletHandler = async() => { 
        const { ethereum } = window;
        if(!ethereum){
          alert("Please Install Metamask!");
        }
    
        if(window.ethereum.networkVersion !== '56'){
            console.log("Please change the MetaMask network to Binance Smart Chain", window.ethereum.networkVersion);
            alert("Please change the MetaMask network to Binance Smart Chain");
            return;
        }
    
        try{
          const accounts = await ethereum.request({method: 'eth_requestAccounts'});
          console.log("Found an account :", accounts[0]);
          setCurrentAccount(accounts[0]);
        }catch (err){
          console.log(err);
        }
      }
    
      const connectWalletButton = () => {
        return (
          <Button onClick={connectWalletHandler} icon="wallet" text="Wallet Connect" className="mc-btn primary m-2" />
        )
      }
    
      const createButton = () => {
        return (
          <Button onClick={createNewContract} icon={createButtonIcon} text={createButtonText} className={createButtonColor} disabled={isLoading} />
        )
      }

    
      useEffect(() => {
        checkWalletIsConnected();
      }, [])

      function timeout() {
        return new Promise( res => setTimeout(res, 5000) );
      }

      const createNewContract = async() => {
        try{
          const { ethereum } = window;
    
          if(ethereum){
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(contractAddress, abi, signer);
    
            console.log("Intialize payment");
            let newUsdtConverter = await contract.createUsdtConverter(ContractText, {value: fee});
            tempNewContract = await contract.getNewUsdtConverter();
            myNewContract = tempNewContract;

            setIsLoading(true);
            setCreateButtonText("Please Wait...");
            setCreateButtonIcon("timer");
            setCreateButtonColor("mc-btn red m-2");

            while(tempNewContract === myNewContract){
              myNewContract = await contract.getNewUsdtConverter();
              await timeout();
            }
        if(newUsdtConverter){
          navigate(`/usdt-converter-contract?id=${myNewContract}&chain=bsc`);
          // alert("Congratulations, your ico launchpad has been created on smart chain testnet.");
          // alert(myNewContract);
          //  console.log(myNewContract);
        }else{
          alert("Something wrong, Some fields missing or maybe you don't have enough BNB balance for transaction fee.");
        }
          }
        }catch(err){
        alert("Something wrong, Some fields missing or maybe you don't have enough BNB balance for transaction fee.");
          console.log(err);
        }
    
      }

    return (
        <PageLayout>
            <Row>
            <Col xl={12}>
                    <Box className="mc-card">
                        <CardHeader title="USDT Converter" />
                        <div>* This contract will conver BNB to USDT automatically and send it to your wallet.</div>
                        <div>* Minimun convert amount: 0.01 BNB, when receive less than 0.01 BNB the contect hold it until balance reached 0.01 BNB or more then it will converted and sent to your wallet.</div>
                        <div>* You can use it to convert your token tax or presale funds from BNB to USDT automatically.</div>
                        <div>* Contract Creation Fee: 0.01 BNB, and every BNB conversion to USDT cost 0.0005 BNB.</div>
                        <Row xs={12} md={12} xl={12}>
                            <Col>
                                <DivideTitle title=" " className="mb-4 mt-3" />
                                <LabelField onChange={handleContractChange} value={ContractText} label="Your Wallet" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="0xAb8a.............." />
                                <div>* This wallet will receive the converted USDT.</div>
                            </Col>
                        </Row>
                    </Box>
                </Col>
                <Col xl={12}>
                    <Box className="mb-4">
                        <Row xs={12} md={3} xl={3} className="justify-content-center">
                          {currentAccount ? createButton() : connectWalletButton()}
                        </Row>
                    </Box>
                </Col>
            </Row>
        </PageLayout>
    )
}