import React from "react";
import { useEffect, useState } from 'react';
import PageLayout from "../../layouts/PageLayout";
import { DivideTitle } from "../../components";
import { Row, Col } from "react-bootstrap";
import { CardHeader } from "../../components/cards";
import { LabelField } from "../../components/fields";
import { Box, Button } from "../../components/elements";
import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import { useNavigate } from "react-router-dom";

export default function CreateLaunchpadPlus() {

    // const contractAddress = "0x645073957f1a12f239Ab81795Ba594b64CD8DB95";
    const abi = [{"inputs":[],"stateMutability":"nonpayable","type":"constructor"},{"inputs":[{"internalType":"address","name":"_seller","type":"address"},{"internalType":"address","name":"_tokenAddress","type":"address"},{"internalType":"uint256","name":"_price","type":"uint256"},{"internalType":"uint256","name":"_tokenDecimal","type":"uint256"},{"internalType":"uint256","name":"_commission","type":"uint256"}],"name":"createPresale","outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"payable","type":"function"},{"inputs":[],"name":"getNewPresale","outputs":[{"internalType":"contract Presale","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"newPresale","outputs":[{"internalType":"contract Presale","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"theOwner","outputs":[{"internalType":"address payable","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address payable","name":"beneficiary","type":"address"}],"name":"withdrawEther","outputs":[],"stateMutability":"nonpayable","type":"function"}];
    // let fee = "50000000000000000";

    let myPresaleContract = "";
    let tempPresaleContract = "";
    const navigate = useNavigate();

    const [currentAccount, setCurrentAccount] = useState(null);
    const [rateText, setrateText] = useState("0.01");
    const [decimalText, setdecimalText] = useState("18");
    const [affiliateCommission, setAffiliateCommission] = useState("7");
    const [ownerText, setOwnerText] = useState(null);
    const [ContractText, setContractText] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [createButtonText, setCreateButtonText] = useState("Create Launchpad")
    const [createButtonIcon, setCreateButtonIcon] = useState("add")
    const [createButtonColor, setCreateButtonColor] = useState("mc-btn green m-2")
    const [contractAddress, setContractAddress] = useState("0x300006fe0F25685bFafE54E941Db82494cF5BA0C");
    const [isSupportedNetwork, setIsSupportedNetwork] = useState(true);
    const [feeText, setfeeText] = useState("50000000000000000");

    
      const handleRateChange = (event) => {
        setrateText(event.target.value);
      };
    
      const handleDecimalChange = (event) => {
        setdecimalText(event.target.value);
      };

      const handleCommissionChange = (event) => {
        setAffiliateCommission(event.target.value);
      };
    
      const handleOwnerChange = (event) => {
        setOwnerText(event.target.value);
      };
    
      const handleContractChange = (event) => {
        setContractText(event.target.value);
      };

      const checkWalletIsConnected = async () => {
        const detectedProvider = await detectEthereumProvider();
        if(!detectedProvider){
          console.log("Metamask NOT Installed");
          return;
        }else{
          console.log("Metamask Installed");
        }
    
       }
    
    
      const connectWalletHandler = async() => { 
        const detectedProvider = await detectEthereumProvider();
        if(!detectedProvider){
          alert("Please Install Metamask!");
        }
    
        try{
          const accounts = await detectedProvider.request({method: 'eth_requestAccounts'});
          console.log("Found an account :", accounts[0]);
          setCurrentAccount(accounts[0]);
        }catch (err){
          console.log(err);
        }
      }
    
      const connectWalletButton = () => {
        return (
          <Button onClick={connectWalletHandler} icon="wallet" text="Wallet Connect" className="mc-btn primary m-2" />
        )
      }
    
      const createButton = () => {
        return (
          <Button onClick={createPresale} icon={createButtonIcon} text={createButtonText} className={createButtonColor} disabled={isLoading} />
        )
      }

      const checkNetwork = async () =>{
        const detectedProvider = await detectEthereumProvider();
        const chainId = await detectedProvider.request({ method: 'eth_chainId' });

        switch (parseInt(chainId, 16)) {
            case 56:
              setContractAddress("0x300006fe0F25685bFafE54E941Db82494cF5BA0C"); // compiler 8.25
              setfeeText("50000000000000000");
              setIsSupportedNetwork(true);
              break;
            default:
              setIsSupportedNetwork(false);
              break;
          }
      }
    
      useEffect(() => {
        checkWalletIsConnected();
        // checkNetwork();
      }, [])

      function timeout() {
        return new Promise( res => setTimeout(res, 5000) );
      }

      const createPresale = async() => {
        checkNetwork();
        if(!isSupportedNetwork){
            alert("Please switch your wallet network to BNB Smart Chain");
            return;
        }

        try{
          const detectedProvider = await detectEthereumProvider();
    
          if(detectedProvider){
            const provider = new ethers.providers.Web3Provider(detectedProvider);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(contractAddress, abi, signer);

            const finalPrice = parseFloat(rateText) * 1000000;
    
            console.log("Intialize payment");
            let newPresale = await contract.createPresale(ownerText, ContractText, finalPrice.toString(), decimalText, affiliateCommission, {value: feeText});
            tempPresaleContract = await contract.getNewPresale();
            myPresaleContract = tempPresaleContract;

            setIsLoading(true);
            setCreateButtonText("Please Wait...");
            setCreateButtonIcon("timer");
            setCreateButtonColor("mc-btn red m-2");

            while(tempPresaleContract === myPresaleContract){
              myPresaleContract = await contract.getNewPresale();
              await timeout();
            }
        if(newPresale){
          navigate(`/contract-launchpad?id=${myPresaleContract}`);
          // alert("Congratulations, your ico launchpad has been created on smart chain testnet.");
          // alert(myPresaleContract);
          //  console.log(myPresaleContract);
        }else{
          alert("Something wrong, Some fields missing or maybe you don't have enough BNB balance for transaction fee.");
        }
          }
        }catch(err){
        alert("Something wrong, Some fields missing or maybe you don't have enough BNB balance for transaction fee.");
          console.log(err);
        }
    
      }

    return (
        <PageLayout>
            <Row>
            <Col xl={12}>
                    <Box className="mc-card">
                        <CardHeader title="Create Launchpad Plus Contract (Accept USDT, USDX, USDC, FDUSD and BNB Payment)" />
                        <p>Supported Blockchains: BNB Smart Chain</p>
                        <p>Creation Fee: 0.05 BNB</p>
                        <p>Download UI: <a href="https://bit.ly/launchpad-ui-plus" target="blank">https://bit.ly/launchpad-ui-plus</a></p>
                        <p>Demo: <a href="https://launchpadplus.0xfactory.com/" target="blank">https://launchpadplus.0xfactory.com</a></p>
                        <p>Remark: the launchpad platform will accept users payments in USDT, USDX, USDC, FDUSD and BNB, when users pay in BNB the platform will convert BNB to USDX and send it to your wallet.</p>
                        <p>Convert USDX to USDT: <a href="https://usdx.0xfactory.com/" target="blank">https://usdx.0xfactory.com</a></p>
                        <Row xs={12} md={12} xl={12}>
                            <Col>
                                <DivideTitle title=" " className="mb-4 mt-3" />
                                <LabelField onChange={handleOwnerChange} value={ownerText} label="Owner Wallet" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="0xAb8a......." />
                                <LabelField onChange={handleContractChange} value={ContractText} label="Token Contract" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="0xAb8a......." />
                                <LabelField onChange={handleRateChange} value={rateText} label="Price in Dollar" type="text" min="1" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="0.01" />
                                <LabelField onChange={handleDecimalChange} value={decimalText} label="Your token decimal" type="text" min="0" max="18" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="18" />
                                <LabelField onChange={handleCommissionChange} value={affiliateCommission} label="Affiliate Commission" type="text" min="0" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="7" />
                            </Col>
                        </Row>
                    </Box>
                </Col>
                <Col xl={12}>
                    <Box className="mb-4">
                        <Row xs={12} md={3} xl={3} className="justify-content-center">
                          {currentAccount ? createButton() : connectWalletButton()}
                        </Row>
                    </Box>
                </Col>
            </Row>
        </PageLayout>
    )
}