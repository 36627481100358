import React from "react";
import { useEffect, useState } from 'react';
import PageLayout from "../../layouts/PageLayout";
import { DivideTitle } from "../../components";
import { Row, Col } from "react-bootstrap";
import { CardHeader } from "../../components/cards";
import { LabelField } from "../../components/fields";
import { Box, Button } from "../../components/elements";
import { ethers } from 'ethers';
import { useNavigate } from "react-router-dom";

export default function PolyLaunchpad() {

    const contractAddress = "0x83928431632BC5a3d190fF55132C7cACCb23FA2e";
    const abi = [{"inputs":[{"internalType":"address","name":"_seller","type":"address"},{"internalType":"address","name":"_tokenAddress","type":"address"},{"internalType":"uint256","name":"_rate","type":"uint256"},{"internalType":"uint256","name":"_tokenDecimal","type":"uint256"}],"name":"createPresale","outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"payable","type":"function"},{"inputs":[],"stateMutability":"nonpayable","type":"constructor"},{"inputs":[{"internalType":"address payable","name":"beneficiary","type":"address"}],"name":"withdrawEther","outputs":[],"stateMutability":"nonpayable","type":"function"},{"inputs":[],"name":"getNewPresale","outputs":[{"internalType":"contract Presale","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"newPresale","outputs":[{"internalType":"contract Presale","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"theOwner","outputs":[{"internalType":"address payable","name":"","type":"address"}],"stateMutability":"view","type":"function"}];
    const fee = "5000000000000000000";

    let myPresaleContract = "";
    let tempPresaleContract = "";
    const navigate = useNavigate();

    const [currentAccount, setCurrentAccount] = useState(null);
    const [rateText, setrateText] = useState(null);
    const [decimalText, setdecimalText] = useState(null);
    const [ownerText, setOwnerText] = useState(null);
    const [ContractText, setContractText] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [createButtonText, setCreateButtonText] = useState("Create Launchpad")
    const [createButtonIcon, setCreateButtonIcon] = useState("add")
    const [createButtonColor, setCreateButtonColor] = useState("mc-btn green m-2")

    
      const handleRateChange = (event) => {
        setrateText(event.target.value);
      };
    
      const handleDecimalChange = (event) => {
        setdecimalText(event.target.value);
      };
    
      const handleOwnerChange = (event) => {
        setOwnerText(event.target.value);
      };
    
      const handleContractChange = (event) => {
        setContractText(event.target.value);
      };

      const checkWalletIsConnected = () => {
        const { ethereum } = window;
        if(!ethereum){
          console.log("Metamask NOT Installed");
          return;
        }else{
          console.log("Metamask Installed");
        }
    
       }
    
    
      const connectWalletHandler = async() => { 
        const { ethereum } = window;
        if(!ethereum){
          alert("Please Install Metamask!");
        }
    
        if(window.ethereum.networkVersion !== '137'){
            console.log("Please change the MetaMask network to Polygon Network", window.ethereum.networkVersion);
            alert("Please change the MetaMask network to Polygon Network");
            return;
        }
    
        try{
          const accounts = await ethereum.request({method: 'eth_requestAccounts'});
          console.log("Found an account :", accounts[0]);
          setCurrentAccount(accounts[0]);
        }catch (err){
          console.log(err);
        }
      }
    
      const connectWalletButton = () => {
        return (
          <Button onClick={connectWalletHandler} icon="wallet" text="Wallet Connect" className="mc-btn primary m-2" />
        )
      }
    
      const createButton = () => {
        return (
          <Button onClick={createPresale} icon={createButtonIcon} text={createButtonText} className={createButtonColor} disabled={isLoading} />
        )
      }

    
      useEffect(() => {
        checkWalletIsConnected();
      }, [])

      function timeout() {
        return new Promise( res => setTimeout(res, 5000) );
      }

      const createPresale = async() => {
        try{
          const { ethereum } = window;
    
          if(ethereum){
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(contractAddress, abi, signer);
    
            console.log("Intialize payment");
            let newPresale = await contract.createPresale(ownerText, ContractText, rateText, decimalText, {value: fee});
            tempPresaleContract = await contract.getNewPresale();
            myPresaleContract = tempPresaleContract;

            setIsLoading(true);
            setCreateButtonText("Please Wait...");
            setCreateButtonIcon("timer");
            setCreateButtonColor("mc-btn red m-2");

            while(tempPresaleContract === myPresaleContract){
              myPresaleContract = await contract.getNewPresale();
              await timeout();
            }
        if(newPresale){
          navigate(`/contract-launchpad?id=${myPresaleContract}&chain=poly`);
          // alert("Congratulations, your ico launchpad has been created on smart chain testnet.");
          // alert(myPresaleContract);
          //  console.log(myPresaleContract);
        }else{
          alert("Something wrong, Some fields missing or maybe you don't have enough MATIC balance for transaction fee.");
        }
          }
        }catch(err){
        alert("Something wrong, Some fields missing or maybe you don't have enough MATIC balance for transaction fee.");
          console.log(err);
        }
    
      }

    return (
        <PageLayout>
            <Row>
            <Col xl={12}>
                    <Box className="mc-card">
                        <CardHeader title="Create ICO Launchpad Contract On Polygon Network" />
                        <Row xs={12} md={12} xl={12}>
                            <Col>
                                <DivideTitle title=" " className="mb-4 mt-3" />
                                <LabelField onChange={handleOwnerChange} value={ownerText} label="Owner Wallet" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="0xAb8a3dD7D4Cd76f094283058F544e6bd67c54RTq" />
                                <LabelField onChange={handleContractChange} value={ContractText} label="Token Contract" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="0xAb8a3dD7D4Cd76f094283058F544e6bd67c54RTq" />
                                <LabelField onChange={handleRateChange} value={rateText} label="Rate (1 MATIC = ???? of your token)" type="number" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="1000" />
                                <LabelField onChange={handleDecimalChange} value={decimalText} label="Your token decimal" type="number" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="18" />
                            </Col>
                        </Row>
                    </Box>
                </Col>
                <Col xl={12}>
                    <Box className="mb-4">
                        <Row xs={12} md={3} xl={3} className="justify-content-center">
                          {currentAccount ? createButton() : connectWalletButton()}
                        </Row>
                    </Box>
                </Col>
            </Row>
        </PageLayout>
    )
}