import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Anchor, Box, Image, Heading } from "../../components/elements";
import { Container, Row, Col } from "react-bootstrap";
import data from "../../data/supports/overview.json";

export default function Apps() {
    return (
        <PageLayout>
            <Container fluid>
                <Heading as="h2" className="mc-overview-title">0xFactory Apps</Heading>
                    <Row xs={1} sm={2} xl={3}>
                        {data?.util.map((demo, index) => (
                            <Col key={index} >
                                <Box className="mc-overview-card">
                                    <Heading className="mc-overview-name">{demo.title}</Heading>
                                    <Box className="mc-overview-media">
                                        <Image src={demo.image} alt="demo" />
                                        <h3>{demo.price}</h3>
                                        <p>
                                            {demo.description}
                                        </p>
                                        <Box className="mc-overview-overlay">
                                            <Anchor href={demo.path} rel="noopener noreferrer" className="mc-overview-link">Download Now</Anchor>
                                        </Box>
                                    </Box>
                                </Box>
                            </Col>
                        ))}
                    </Row>
                </Container>
        </PageLayout>
    )
}