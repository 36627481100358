import React from "react";
import { Image, List, Box, Text, Item, Button } from "../../components/elements";
import { CardLayout } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import { ethers } from 'ethers';

export default function TokensContracts() {


    const addPolyNetwork = async() => {
        try {
            const { ethereum } = window;
            if(ethereum){
                await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [{
                chainId: ethers.utils.hexValue(137),
                rpcUrls: ["https://polygon-rpc.com/"],
                chainName: "Polygon Mainnet",
                nativeCurrency: {
                    name: "MATIC",
                    symbol: "MATIC",
                    decimals: 18
                },
                blockExplorerUrls: ["https://polygonscan.com/"]
                }]
            });
            }
        } catch (error){
          console.log(error)
        }
    }

    const addTestNetwork = async() => {
        try {
            const { ethereum } = window;
            if(ethereum){
                await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [{
                chainId: ethers.utils.hexValue(97),
                rpcUrls: ["https://bsc-testnet.public.blastapi.io"],
                chainName: "Binance Smart Chain Testnet",
                nativeCurrency: {
                    name: "tBNB",
                    symbol: "tBNB",
                    decimals: 18
                },
                blockExplorerUrls: ["https://testnet.bscscan.com/"]
                }]
            });
            }
        } catch (error){
          console.log(error)
        }
    }

    const addFantomNetwork = async() => {
        try {
            const { ethereum } = window;
            if(ethereum){
                await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [{
                chainId: ethers.utils.hexValue(250),
                rpcUrls: ["https://rpc.ftm.tools"],
                chainName: "Fantom Opera",
                nativeCurrency: {
                    name: "FTM",
                    symbol: "FTM",
                    decimals: 18
                },
                blockExplorerUrls: ["https://ftmscan.com"]
                }]
            });
            }
        } catch (error){
          console.log(error)
        }
    }

    const addAvaxNetwork = async() => {
        try {
            const { ethereum } = window;
            if(ethereum){
                await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [{
                chainId: ethers.utils.hexValue(43114),
                rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
                chainName: "Avalanche C-Chain",
                nativeCurrency: {
                    name: "AVAX",
                    symbol: "AVAX",
                    decimals: 18
                },
                blockExplorerUrls: ["https://snowtrace.io"]
                }]
            });
            }
        } catch (error){
          console.log(error)
        }
    }

    const addKronobitNetwork = async() => {
        try {
            const { ethereum } = window;
            if(ethereum){
                await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [{
                chainId: ethers.utils.hexValue(13600),
                rpcUrls: ["https://mainnet-rpc.qbitscan.com/"],
                chainName: "Kronobit Networks",
                nativeCurrency: {
                    name: "KNB",
                    symbol: "KNB",
                    decimals: 18
                },
                blockExplorerUrls: ["https://explorer.qbitscan.com/"]
                }]
            });
            }
        } catch (error){
          console.log(error)
        }
    }

        const addBnbNetwork = async() => {
            try {
                await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [{
                  chainId: ethers.utils.hexValue(56),
                  rpcUrls: ["https://bsc-dataseed.binance.org/"],
                  chainName: "Binance Smart Chain",
                  nativeCurrency: {
                    name: "BNB",
                    symbol: "BNB",
                    decimals: 18
                  },
                  blockExplorerUrls: ["https://bscscan.com"]
                }]
              });
            } catch (error){
              console.log(error)
            }
        }

        const addBaseNetwork = async() => {
            try {
                await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [{
                  chainId: ethers.utils.hexValue(8453),
                  rpcUrls: ["https://developer-access-mainnet.base.org"],
                  chainName: "Base Network",
                  nativeCurrency: {
                    name: "ETH",
                    symbol: "ETH",
                    decimals: 18
                  },
                  blockExplorerUrls: ["https://basescan.org"]
                }]
              });
            } catch (error){
              console.log(error)
            }
        }


    return (
        <PageLayout>
            <CardLayout className="mb-4">
                <Breadcrumb title="Add Chains to MetaMask"></Breadcrumb>
            </CardLayout>

            <CardLayout>
                <List className="mc-notify-list">

                        <Item className="mc-notify-item" key="token">
                                <Box className="mc-notify-media">
                                    <Image src="images/bep20.png" alt="create token" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">BNB Smart Chain</Text> 
                                    <Button onClick={addBnbNetwork} className="mc-btn green m-2">Add Chain</Button>                               
                                </Box>
                        </Item>

                        <Item className="mc-notify-item" key="token">
                                <Box className="mc-notify-media">
                                    <Image src="images/base.svg" alt="create token" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">Base Network</Text> 
                                    <Button onClick={addBaseNetwork} className="mc-btn green m-2">Add Chain</Button>                               
                                </Box>
                        </Item>

                        <Item className="mc-notify-item" key="token">
                                <Box className="mc-notify-media">
                                    <Image src="images/poly.png" alt="create token" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">Polygon Network</Text> 
                                    <Button onClick={addPolyNetwork} className="mc-btn green m-2">Add Chain</Button>                               
                                </Box>
                        </Item>

                        <Item className="mc-notify-item" key="token">
                                <Box className="mc-notify-media">
                                    <Image src="images/avax.png" alt="create token" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">Avalanche Network</Text> 
                                    <Button onClick={addAvaxNetwork} className="mc-btn green m-2">Add Chain</Button>                               
                                </Box>
                        </Item>

                        <Item className="mc-notify-item" key="token">
                                <Box className="mc-notify-media">
                                    <Image src="images/ftm.png" alt="create token" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">Fantom Network</Text> 
                                    <Button onClick={addFantomNetwork} className="mc-btn green m-2">Add Chain</Button>                               
                                </Box>
                        </Item>

                        <Item className="mc-notify-item" key="token">
                                <Box className="mc-notify-media">
                                    <Image src="images/qbit.png" alt="create token" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">Kronobit Network</Text> 
                                    <Button onClick={addKronobitNetwork} className="mc-btn green m-2">Add Chain</Button>                               
                                </Box>
                        </Item>

                        <Item className="mc-notify-item" key="token">
                                <Box className="mc-notify-media">
                                    <Image src="images/bep20.png" alt="create token" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">BNB Smart Chain TestNet</Text> 
                                    <Button onClick={addTestNetwork} className="mc-btn green m-2">Add Chain</Button>                               
                                </Box>
                        </Item>
   
                    
                </List>
            </CardLayout>
        </PageLayout>
    )
}