import React from "react";
import { useEffect, useState } from 'react';
import PageLayout from "../../layouts/PageLayout";
import { DivideTitle } from "../../components";
import { Row, Col } from "react-bootstrap";
import { CardHeader } from "../../components/cards";
import { LabelField } from "../../components/fields";
import { Box, Button } from "../../components/elements";
import { ethers } from 'ethers';

export default function AvaxToken() {

    const contractAddress = "0x509c97d4A7b98C91f1264304f228e3639416F118";
    const abi = [{"inputs":[],"stateMutability":"nonpayable","type":"constructor"},{"inputs":[{"internalType":"address","name":"cOwner","type":"address"},{"internalType":"address","name":"marketingWallet","type":"address"},{"internalType":"string","name":"name","type":"string"},{"internalType":"string","name":"symbol","type":"string"},{"internalType":"uint256","name":"totalSupply","type":"uint256"},{"internalType":"uint256","name":"taxFee","type":"uint256"}],"name":"createToken","outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"payable","type":"function"},{"inputs":[],"name":"theOwner","outputs":[{"internalType":"address","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address payable","name":"beneficiary","type":"address"}],"name":"withdrawEther","outputs":[],"stateMutability":"nonpayable","type":"function"}];
    const fee = "500000000000000000";
  
    const [currentAccount, setCurrentAccount] = useState(null);
    const [nameText, setNameText] = React.useState(null);
    const [symbolText, setSymbolText] = React.useState(null);
    const [supplyText, setSupplyText] = React.useState(null);
    const [taxText, setTaxText] = React.useState(null);
    const [ownerText, setOwnerText] = React.useState(null);
    const [marketingText, setMarketingText] = React.useState(null);

    const handleNameChange = (event) => {
        setNameText(event.target.value);
      };
    
      const handleSymbolChange = (event) => {
        setSymbolText(event.target.value);
      };
    
      const handleSupplyChange = (event) => {
        setSupplyText(event.target.value);
      };
    
      const handleTaxChange = (event) => {
        setTaxText(event.target.value);
      };
    
      const handleOwnerChange = (event) => {
        setOwnerText(event.target.value);
      };
    
      const handleMarketingChange = (event) => {
        setMarketingText(event.target.value);
      };

      const checkWalletIsConnected = () => {
        const { ethereum } = window;
        if(!ethereum){
          console.log("Metamask NOT Installed");
          return;
        }else{
          console.log("Metamask Installed");
        }
    
       }
    
    
      const connectWalletHandler = async() => { 
        const { ethereum } = window;
        if(!ethereum){
          alert("Please Install Metamask!");
        }
    
        if(window.ethereum.networkVersion !== '43114'){
            console.log("Please change the MetaMask network to Avalanche C-Chain Network", window.ethereum.networkVersion);
            alert("Please change the MetaMask network to Avalanche C-Chain Network");
            return;
        }
    
        try{
          const accounts = await ethereum.request({method: 'eth_requestAccounts'});
          console.log("Found an account :", accounts[0]);
          setCurrentAccount(accounts[0]);
        }catch (err){
          console.log(err);
        }
      }
    
      const connectWalletButton = () => {
        return (
          <Button onClick={connectWalletHandler} icon="wallet" text="Wallet Connect" className="mc-btn primary m-2" />
        )
      }
    
      const createButton = () => {
        return (
          <Button onClick={createToken} icon="add" text="Create Token" className="mc-btn green m-2" />
        )
      }
    
      useEffect(() => {
        checkWalletIsConnected();
      }, [])

      const createToken = async() => {
        try{
          const { ethereum } = window;
    
          if(ethereum){
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(contractAddress, abi, signer);
    
            console.log("Intialize payment");
            let createToken = await contract.createToken(ownerText, marketingText, nameText, symbolText, supplyText, taxText, {value: fee});
            console.log(contract);
        if(createToken){
          alert("Congratulations, your token has been created on Avalanche C-Chain Network.");
        }else{
          alert("Something wrong, Some fields missing or maybe you don't have enough AVAX balance for transaction fee.");
        }
          }
        }catch(err){
        alert("Something wrong, Some fields missing or maybe you don't have enough AVAX balance for transaction fee.");
          console.log(err);
        }
    
      }

    return (
        <PageLayout>
            <Row>
            <Col xl={12}>
                    <Box className="mc-card">
                        <CardHeader title="Create ERC20 Token On Avalanche C-Chain Network" />
                        <Row xs={12} md={12} xl={12}>
                            <Col>
                                <DivideTitle title=" " className="mb-4 mt-3" />
                                <LabelField onChange={handleNameChange} value={nameText} label="Token Name" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="Bitcoin" />
                                <LabelField onChange={handleSymbolChange} value={symbolText} label="Symbol" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="BTC" />
                                <LabelField onChange={handleSupplyChange} value={supplyText} label="Supply" type="number" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="21000000" />
                                <LabelField onChange={handleTaxChange} value={taxText} label="Tax" type="number" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="2" />
                                <LabelField onChange={handleOwnerChange} value={ownerText} label="Owner Wallet" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="0xAb8a3dD7D4Cd76f094283058F544e6bd67c54RTq" />
                                <LabelField onChange={handleMarketingChange} value={marketingText} label="Marketing Wallet" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="0xAb8a3dD7D4Cd76f094283058F544e6bd67c54RTq" />
                            </Col>
                        </Row>
                    </Box>
                </Col>
                <Col xl={12}>
                    <Box className="mb-4">
                        <Row xs={12} md={3} xl={3} className="justify-content-center">
                          {currentAccount ? createButton() : connectWalletButton()}
                        </Row>
                    </Box>
                </Col>
            </Row>
        </PageLayout>
    )
}