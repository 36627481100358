import { ThemeProvider } from "./context/Themes";
import { LoaderProvider } from "./context/Preloader";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Documentation, ChangeLog, Error } from "./pages/supports";
import { Avatars, Alerts, Buttons, Charts, Tables, Fields, Headings, Colors } from "./pages/blocks";
import { Ecommerce, Analytics, CRM, ForgotPassword, UserList, UserProfile, MyAccount, 
    ProductList, ProductView, ProductUpload, InvoiceList, InvoiceDetails, OrderList, Message, 
    Notification, BlankPage, Settings } from "./pages/master";
import { BscToken, EthToken, PolyToken, AvaxToken, FtmToken, TestToken, QbitToken, StratisToken, CoreToken, ReiToken, TronToken } from "./pages/tokens";
import { HomePage, ComingSoon, Zxf, ZxfTr, ZxfRu, ZxfGr, TokensCreator, Tut } from "./pages/home";
import { TestLaunchpad, Contract, Bsc, Poly, Avax, Ftm, Core, CreateLaunchpad, CreateLaunchpadPlus } from "./pages/launchpad";
import { CreateAirdrop, TestAirdrop, ContractAirdrop, BscAirdrop, AvaxAirdrop, FtmAirdrop, CoreAirdrop, PolyAirdrop } from "./pages/airdrop";
import { TokensContracts, UtilContracts, Apps, FreeApps, PremiumApps } from "./pages/contracts";
import { AddChain } from "./pages/tools";
import { BuyBurn, BuyBurnContract } from "./pages/buyBurn";
import { AutoBuy, AutoBuyContract } from "./pages/autoBuy";
import { EasySell, EasySellContract } from "./pages/easySell";
import { EasyBuy, EasyBuyContract } from "./pages/easyBuy";
import { UsdtConverter, UsdtConverterContract} from "./pages/usdtConcerter";
import Marketing from "./pages/marketing/marketing";
import { Eth, BscChain, Base, Arbitrum, StratisChain, PolygonChain, CoreChain, KronobitChain } from "./pages/blockchains";
import { Dex, Cex, Swap, StableSwap, StableSwapContract, Trading } from "./pages/exchanges";


export default function App() {
    return (
        <ThemeProvider>
            <LoaderProvider>
                <BrowserRouter>
                    <Routes>
                        {/* Home Page */}
                        <Route path="*" element={<Error />} />
                        <Route path="/" element={<HomePage />} />
                        <Route path="/zxf" element={<Zxf />} />
                        <Route path="/presale" element={<Zxf />} />
                        <Route path="/private-sale" element={<Zxf />} />
                        <Route path="/tr" element={<ZxfTr />} />
                        <Route path="/ru" element={<ZxfRu />} />
                        <Route path="/gr" element={<ZxfGr />} />
                        <Route path="/dex" element={<Dex />} />
                        <Route path="/0xswap" element={<Swap />} />
                        <Route path="/stable-swap" element={<StableSwap />} />
                        <Route path="/trading-bot" element={<Trading />} />
                        <Route path="/stable-swap-contract" element={<StableSwapContract />} />
                        <Route path="/cex-listing" element={<Cex />} />
                        <Route path="/marketing" element={<Marketing />} />
                        <Route path="/tokens-creator" element={<TokensCreator />} />
                        {/* Blockchains Pages */}
                        <Route path="/smartchain" element={<BscChain /> } />
                        <Route path="/ethereum" element={<Eth /> } />
                        <Route path="/base" element={<Base /> } />
                        <Route path="/arbitrum" element={<Arbitrum /> } />
                        <Route path="/polygon" element={<PolygonChain /> } />
                        <Route path="/stratis" element={<StratisChain /> } />
                        <Route path="/core" element={<CoreChain /> } />
                        <Route path="/kronobit" element={<KronobitChain /> } />
                        {/* Tokens Pages */}
                        <Route path="/bsc-token" element={<BscToken /> } />
                        <Route path="/eth-token" element={<EthToken /> } />
                        <Route path="/poly-token" element={<PolyToken /> } />
                        <Route path="/tron-token" element={<TronToken /> } />
                        <Route path="/avax-token" element={<AvaxToken /> } />
                        <Route path="/ftm-token" element={<FtmToken /> } />
                        <Route path="/stratis-token" element={<StratisToken /> } />
                        <Route path="/rei-token" element={<ReiToken /> } />
                        <Route path="/core-token" element={<CoreToken /> } />
                        <Route path="/test-token" element={<TestToken /> } />
                        <Route path="/knb-token" element={<QbitToken /> } />
                        <Route path="/token-affiliate" element={<EasySell /> } />
                        <Route path="/token-affiliate-contract" element={<EasySellContract /> } />
                        {/* Launchpad Pages */}
                        <Route path="/launchpad-contract" element={<CreateLaunchpad /> } />
                        <Route path="/launchpad-contract-plus" element={<CreateLaunchpadPlus /> } />
                        <Route path="/bsc-launchpad" element={<Bsc /> } />
                        <Route path="/eth-launchpad" element={<ComingSoon /> } />
                        <Route path="/poly-launchpad" element={<Poly /> } />
                        <Route path="/avax-launchpad" element={<Avax /> } />
                        <Route path="/ftm-launchpad" element={<Ftm /> } />
                        <Route path="/core-launchpad" element={<Core /> } />
                        <Route path="/test-launchpad" element={<TestLaunchpad /> } />
                        <Route path="/contract-launchpad" element={<Contract /> } />
                        {/* Airdrop Pages */}
                        <Route path="/airdrop-contract" element={<CreateAirdrop /> } />
                        <Route path="/bsc-airdrop" element={<BscAirdrop /> } />
                        <Route path="/eth-airdrop" element={<ComingSoon /> } />
                        <Route path="/poly-airdrop" element={<PolyAirdrop /> } />
                        <Route path="/avax-airdrop" element={<AvaxAirdrop /> } />
                        <Route path="/ftm-airdrop" element={<FtmAirdrop /> } />
                        <Route path="/core-airdrop" element={<CoreAirdrop /> } />
                        <Route path="/test-airdrop" element={<TestAirdrop /> } />
                        <Route path="/contract-airdrop" element={<ContractAirdrop /> } />
                        {/* Solo Pages */}
                        <Route path="/usdt-converter" element={<UsdtConverter /> } />
                        <Route path="/usdt-converter-contract" element={<UsdtConverterContract /> } />
                        <Route path="/buy-burn" element={<BuyBurn /> } />
                        <Route path="/buy-burn-contract" element={<BuyBurnContract /> } />
                        <Route path="/easy-buy" element={<EasyBuy /> } />
                        <Route path="/easy-buy-contract" element={<EasyBuyContract /> } />
                        <Route path="/auto-buy" element={<AutoBuy /> } />
                        <Route path="/auto-buy-contract" element={<AutoBuyContract /> } />

                        {/* Download Contracts Pages */}
                        <Route path="/tokens-contracts" element={<TokensContracts /> } />
                        <Route path="/dapps" element={<Apps /> } />
                        <Route path="/free-dapps" element={<FreeApps /> } />
                        <Route path="/premium-dapps" element={<PremiumApps /> } />
                        <Route path="/contracts" element={<UtilContracts /> } />
                        <Route path="/nft" element={<ComingSoon /> } />

                        {/* Tools */}
                        <Route path="/video-tutorials" element={<Tut /> } />
                        <Route path="/add-chain" element={<AddChain /> } />
                        <Route path="/airdrop-ui" element={<ComingSoon /> } />


                        {/* master Pages */}
                        <Route path="/ecommerce" element={<Ecommerce /> } />
                        <Route path="/analytics" element={<Analytics /> } />
                        <Route path="/crm" element={<CRM /> } />
                        <Route path="/login" element={<HomePage />} />
                        <Route path="/register" element={<HomePage />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/user-list" element={<UserList />} />
                        <Route path="/user-profile" element={<UserProfile />} />
                        <Route path="/my-account" element={<MyAccount />} />
                        <Route path="/product-list" element={<ProductList />} />
                        <Route path="/product-view" element={<ProductView />} />
                        <Route path="/product-upload" element={<ProductUpload />} />
                        <Route path="/invoice-list" element={<InvoiceList />} />
                        <Route path="/invoice-details" element={<InvoiceDetails />} />
                        <Route path="/order-list" element={<OrderList />} />
                        <Route path="/message" element={<Message />} />
                        <Route path="/notification" element={<Notification />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/blank-page" element={<BlankPage />} />

                        {/* Blocks Pages */} 
                        <Route path="/headings" element={<Headings />} />
                        <Route path="/buttons" element={<Buttons />} />
                        <Route path="/avatars" element={<Avatars />} />
                        <Route path="/colors" element={<Colors />} />
                        <Route path="/charts" element={<Charts />} />
                        <Route path="/tables" element={<Tables />} />
                        <Route path="/fields" element={<Fields />} />
                        <Route path="/alerts" element={<Alerts />} />

                        {/* Supports Pages */}
                        <Route path="/documentation" element={<Documentation />} />
                        <Route path="/changelog" element={<ChangeLog />} />
                    </Routes>
                </BrowserRouter>
            </LoaderProvider>
        </ThemeProvider>
    );
}

